/* Exemple de CSS pour le survol */
.amigurumi-details {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid black;
  padding: 10px;
}

.amigurumi-item:hover .amigurumi-details {
  display: block;
}

/* Réinitialisation des styles */
body, h1, p, a, img {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: Arial, Helvetica, sans-serif;
}

/* Styles pour le header */
.header {
  background-color: #333;
  color: #fff;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .header {
    flex-direction: column;
    text-align: center;
  }
}
.header__logo img {
  max-width: 80%;
  height: auto;
}
.header__content {
  text-align: left;
}
@media screen and (max-width: 768px) {
  .header__content {
    margin-top: 20px;
  }
}
.header__title {
  font-size: 24px;
  margin-bottom: 10px;
}
.header__quote {
  font-style: italic;
  margin-bottom: 20px;
}
.header__button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff5722;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}
.header__button:hover {
  background-color: #ff8a65;
}


.event {
  width: 100%;
}

.event__title {
  text-align: center;
  margin: 2rem;
  text-transform: uppercase;
}

.event__date {
  text-align: center;
  font-style: italic;
}

.event__description {
  text-align: center;
  padding: 2rem;
}

.event__image {
  max-width: 100%;
  min-width: 100%;
}

.pagination {
  justify-content: center;
  padding: 2rem;
}

#amigurumi {
  color: #fff;
  background-color: #333;
}

.amigurumi__bigtitle {
  text-align: center;
  text-transform: uppercase;
  padding: 2rem;
}

.amigurumi__card {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.amigurumi__image {
  width: 100%;
}

.card {
  width: 30%;
}

.Social img{
  max-width: 100%;
}

.Social__button {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  background-color: #ff5722;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease-in-out;
}
.Social__text {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.Social__text h2 {
  text-transform: uppercase;
  text-align: center;
  padding: 2rem;
}

.Social__text p {
  text-align: center;
  margin: 2rem;
}

.Social__button:hover {
  background: #ff8a65;
}

.footer__logo img{
  max-width: 10dvm;
  height: auto;
  margin: 1rem;
}

footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  background-color: #333;
}

.footer__copyright {
  font-size: xx-small;
}

body, h1, p, a, img {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: Arial, Helvetica, sans-serif;
}

/* Styles pour le header */
.header {
  background-color: #333;
  color: #fff;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .header {
    flex-direction: column;
    text-align: center;
  }
}
.header__logo img {
  max-width: 80%;
  height: auto;
}
.header__content {
  text-align: left;
}
@media screen and (max-width: 768px) {
  .header__content {
    margin-top: 20px;
  }
}
.header__title {
  font-size: 24px;
  margin-bottom: 10px;
}
.header__quote {
  font-style: italic;
  margin-bottom: 20px;
}
.header__button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff5722;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}
.header__button:hover {
  background-color: #ff8a65;
}

/* Styles pour la section de landing page */
.landing-section {
  position: relative;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: Arial, sans-serif;
}

/* Styles pour l'image de fond avec effet de parallaxe */
.landing-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  filter: blur(5px);
  z-index: -1;
}

/* Styles pour le contenu de la section */
.landing-content {
  text-align: center;
  padding: 20px;
}

/* Styles pour le titre */
.landing-title {
  font-size: 36px;
  margin-bottom: 20px;
}

/* Styles pour la description */
.landing-description {
  font-size: 18px;
  margin-bottom: 30px;
}

/* Styles pour le bouton */
.landing-button {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  background-color: #ff5722;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease-in-out;
}
.landing-button:hover {
  background-color: #ff8a65;
}

/* Styles pour la section des valeurs de l'entreprise */
.values-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: #ff7f2a;
  padding: 20px;
  z-index: 1;
}

/* Styles pour les cartes de valeurs */
.value-card {
  width: calc(33.33% - 20px);
  background-color: #fff;
  margin: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .value-card {
    width: calc(50% - 20px);
  }
}
@media screen and (max-width: 480px) {
  .value-card {
    width: 100%;
  }
}
.value-card img {
  max-width: 20dvw;
  height: auto;
}
.value-card .value-description {
  font-size: 16px;
  margin-top: 10px;
}

/* Styles pour le footer */
.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Styles pour le logo */
.footer-logo img {
  max-width: 20dvw;
  height: auto;
  margin: 1rem;
}

/* Styles pour le contenu du footer */
.footer-content {
  text-align: left;
}

/* Styles pour le titre */
.footer-title {
  font-size: 24px;
  margin-bottom: 10px;
}

/* Styles pour le bouton */
.footer-button {
  background-color: #ff5722;
  color: #fff;
  padding: 10px 20px;
  margin: 1rem;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}
.footer-button:hover {
  background-color: #ff8a65;
}

/* Styles pour le copyright */
.footer-copyright {
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
}

.ma-section {
  background-color: #f0f0f0;
  padding: 20px;
  z-index: -1;
}
.ma-section .contenu {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ma-section .contenu .image {
  max-width: 100%;
  margin: 1rem;
}
.ma-section .contenu .texte {
  text-align: center;
  margin-top: 20px;
}
.ma-section .contenu .texte h2 {
  font-size: 24px;
  margin-bottom: 10px;
}
.ma-section .contenu .texte p {
  font-size: 16px;
}
.ma-section .contenu .texte a {
  margin-top: 1rem;
}
@media screen and (min-width: 768px) {
  .ma-section .contenu {
    flex-direction: row;
  }
  .ma-section .contenu .image {
    max-width: 50%;
    margin-right: 20px;
  }
}



/*# sourceMappingURL=styles.css.map */
